import { getImageUrl } from "./image";

export const KnownImages = {
	LOGO_FULL_COLOR_TRANSPARENT: getImageUrl("logos/dein-ticket.shop/full_color_transparent"),
	LOGO_BLACK_TRANSPARENT: getImageUrl("logos/dein-ticket.shop/black_transparent"),
	LOGO_BLACK_TRANSPARENT_SVG: "/logo.svg",
	ICON: getImageUrl("logos/dein-ticket.shop/icon"),
	GENERIC_EVENT_1: getImageUrl("assets/generics/event1"),
	GENERIC_EVENT_2: getImageUrl("assets/generics/event2"),
	GENERIC_OFFICE: getImageUrl("assets/generics/office"),
	GENERIC_COLLAGE: getImageUrl("assets/layout/collage"),
	GENERIC_COLLAGE_MOBILE: getImageUrl("assets/layout/collage/mobile"),
};
